import './footer1.scss'; 
import logoImg from '../../../img/logo.png'; 


const Footer1 = () => {
    const footerElement = document.createElement('footer');
    footerElement.classList.add('footer', 'footer1');

    footerElement.innerHTML = `
    <div class="footer1__container">
    <img src="${logoImg}" alt="logo/png" class="footer1__logo" width="129" height="50"> 
    <a href="./conf.html" class="footer1__link" target="_blank">Политика конфиденциальности</a>
    </div>
    `;
    return footerElement;
   
};

export default Footer1;